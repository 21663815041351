
import React, { Component } from "react";
import { TweenMax, Quart } from 'gsap';
import $ from 'jquery';

import "./styles.css";

class Countdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        };
    }

    componentDidUpdate(prevProps) {

        const { wait, days, hours, minutes, seconds } = this.props;

        if (wait !== prevProps.wait || days !== prevProps.days || hours !== prevProps.hours || minutes !== prevProps.minutes || seconds !== prevProps.seconds) {

            this.values = { days, hours, minutes, seconds };
            this.total_seconds = wait;
            this.count();

        }

    }

    count = () => {

        clearInterval(this.countdown_interval);

        var that = this,
            $day_1 = $(this.day_1),
            $day_2 = $(this.day_2),
            $hour_1 = $(this.hour_1),
            $hour_2 = $(this.hour_2),
            $min_1 = $(this.min_1),
            $min_2 = $(this.min_2),
            $sec_1 = $(this.sec_1),
            $sec_2 = $(this.sec_2);

        this.countdown_interval = setInterval(function () {

            if (that.total_seconds > 0) {

                --that.values.seconds;

                if (that.state.minutes >= 0 && that.values.seconds < 0) {

                    that.values.seconds = 59;
                    --that.values.minutes;
                }

                if (that.values.hours >= 0 && that.values.minutes < 0) {

                    that.values.minutes = 59;
                    --that.values.hours;
                }

                if (that.values.days >= 0 && that.values.hours < 0) {

                    that.values.hours = 24;
                    --that.values.days;
                }

                that.checkHour(that.values.days, $day_1, $day_2);
                that.checkHour(that.values.hours, $hour_1, $hour_2);
                that.checkHour(that.values.minutes, $min_1, $min_2);
                that.checkHour(that.values.seconds, $sec_1, $sec_2);

                --that.total_seconds;
            }
            else {
                clearInterval(that.countdown_interval);
            }
        }, 1000);

    }

    animateFigure = ($el, value) => {

        var $top = $el.find('.top'),
            $bottom = $el.find('.bottom'),
            $back_top = $el.find('.top-back'),
            $back_bottom = $el.find('.bottom-back');

        $back_top.find('span').html(value);

        $back_bottom.find('span').html(value);

        TweenMax.to($top, 0.8, {
            rotationX: '-180deg',
            transformPerspective: 300,
            ease: Quart.easeOut,
            onComplete: function () {

                $top.html(value);

                $bottom.html(value);

                TweenMax.set($top, { rotationX: 0 });
            }
        });

        TweenMax.to($back_top, 0.8, {
            rotationX: 0,
            transformPerspective: 300,
            ease: Quart.easeOut,
            clearProps: 'all'
        });
    }

    checkHour = (value, $el_1, $el_2) => {

        var val_1 = value.toString().charAt(0),
            val_2 = value.toString().charAt(1),
            fig_1_value = $el_1.find('.top').html(),
            fig_2_value = $el_2.find('.top').html();

        if (value >= 10) {
            if (fig_1_value !== val_1) this.animateFigure($el_1, val_1);
            if (fig_2_value !== val_2) this.animateFigure($el_2, val_2);
        } else {
            if (fig_1_value !== '0') this.animateFigure($el_1, 0);
            if (fig_2_value !== val_1) this.animateFigure($el_2, val_1);
        }
    }

    render() {
        const {days, hours, minutes, seconds } = this.props;
        return (
            <div className="countdown">
                <div className="bloc-time days" data-init-value={days}>
                    <span className="count-title">Dias</span>

                    <div className="figure days days-1" ref={ref => this.day_1 = ref}>
                        <span className="top">0</span>
                        <span className="top-back">
                            <span>0</span>
                        </span>
                        <span className="bottom">0</span>
                        <span className="bottom-back">
                            <span>0</span>
                        </span>
                    </div>

                    <div className="figure days days-2" ref={ref => this.day_2 = ref}>
                        <span className="top">0</span>
                        <span className="top-back">
                            <span>0</span>
                        </span>
                        <span className="bottom">0</span>
                        <span className="bottom-back">
                            <span>0</span>
                        </span>
                    </div>
                </div>

                <div className="bloc-time hours" data-init-value={hours}>
                    <span className="count-title">Horas</span>

                    <div className="figure hours hours-1" ref={ref => this.hour_1 = ref}>
                        <span className="top">0</span>
                        <span className="top-back">
                            <span>0</span>
                        </span>
                        <span className="bottom">0</span>
                        <span className="bottom-back">
                            <span>0</span>
                        </span>
                    </div>

                    <div className="figure hours hours-2" ref={ref => this.hour_2 = ref}>
                        <span className="top">0</span>
                        <span className="top-back">
                            <span>0</span>
                        </span>
                        <span className="bottom">0</span>
                        <span className="bottom-back">
                            <span>0</span>
                        </span>
                    </div>
                </div>

                <div className="bloc-time min" data-init-value={minutes}>
                    <span className="count-title">Minutos</span>

                    <div className="figure min min-1" ref={ref => this.min_1 = ref}>
                        <span className="top">0</span>
                        <span className="top-back">
                            <span>0</span>
                        </span>
                        <span className="bottom">0</span>
                        <span className="bottom-back">
                            <span>0</span>
                        </span>
                    </div>

                    <div className="figure min min-2" ref={ref => this.min_2 = ref}>
                        <span className="top">0</span>
                        <span className="top-back">
                            <span>0</span>
                        </span>
                        <span className="bottom">0</span>
                        <span className="bottom-back">
                            <span>0</span>
                        </span>
                    </div>
                </div>

                <div className="bloc-time sec" data-init-value={seconds}>
                    <span className="count-title">Segundos</span>

                    <div className="figure sec sec-1" ref={ref => this.sec_1 = ref}>
                        <span className="top">0</span>
                        <span className="top-back">
                            <span>0</span>
                        </span>
                        <span className="bottom">0</span>
                        <span className="bottom-back">
                            <span>0</span>
                        </span>
                    </div>

                    <div className="figure sec sec-2" ref={ref => this.sec_2 = ref}>
                        <span className="top">0</span>
                        <span className="top-back">
                            <span>0</span>
                        </span>
                        <span className="bottom">0</span>
                        <span className="bottom-back">
                            <span>0</span>
                        </span>
                    </div>
                </div>
            </div>
        );
    }

}

export default Countdown;