
import React, { Component } from "react";
import moment from 'moment';
import API from './api';
import LoadingOverlay from "react-loading-overlay";
import Countdown from './components/Countdown';

const initialState = {
  now: undefined,
  live: undefined,
  video: false,
  display: undefined,
  banner: undefined,
  lives: [],
  wait: 0,
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
  showModal: false,
  comments: [],
  token: undefined
}

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...initialState
    };
  }

  async componentDidMount() {
    await this.load();
    (window.FB && window.FB.XFBML) && window.FB.XFBML.parse();
  }

  componentWillUpdate() {

  }

  load = async () => {

    await this.now();
    await this.next();
    await this.lives();

  }

  now = async () => {
    let now = undefined;
    try {
      this.setState({ loading: true });
      let res = await API.get(`api/lives/now`);
      now = res.data.now;
    } catch (error) {
      console.log(error);
    }
    this.setState({ now, loading: false });
  }

  next = async () => {

    const { now } = this.state;

    try {

      this.setState({ loading: true });
      let res = await API.get(`api/next`);
      let live = res.data;

      if (live) {

        let p_date_at = moment(live.date_at, 'YYYY-MM-DD HH:mm:ss').toDate();
        let p_now = moment(now, 'YYYY-MM-DD HH:mm:ss').toDate();
        let duration = moment.duration(moment(p_date_at).diff(moment(p_now)));
        let wait = duration.asSeconds();

        if (wait >= 0) {

          let days = duration.days();
          let hours = duration.hours();
          let minutes = duration.minutes();
          let seconds = duration.seconds();

          this.setState({ live: undefined, display: live.display, banner: live.banner, wait, days, hours, minutes, seconds });

          setTimeout(this.load, wait * 1000)

        } else {

          // let res = await API.get(`api/next/token`);
          // const { token } = res.data;
          this.setState({ live, token: undefined, display: undefined, banner: undefined, wait: 0, days: 0, hours: 0, minutes: 0, seconds: 0 });

        }

      }

      this.setState({ loading: false });

    } catch (error) {
      console.log(error);
    }

  }

  lives = async () => {
    let lives = [];
    try {
      this.setState({ loading: true });
      let res = await API.get(`api/lives`);
      lives = res.data;
    } catch (error) {
      console.log(error);
    }
    this.setState({ lives, loading: false });
  }

  openVideo = (live) => {
    this.setState({ live, video: true }, () => {
      (window.FB && window.FB.XFBML) && window.FB.XFBML.parse();
    });
  }

  render() {
    const { live, display, banner, wait, days, hours, minutes, seconds } = this.state;
    return (
      <>
        {
          this.state.loading && (
            <LoadingOverlay
              active={this.state.loading}
              spinner
              styles={{
                wrapper: {
                  overflow: this.state.loading ? 'hidden' : 'scroll',
                  position: 'fixed',
                  width: '100%',
                  height: '100%',
                  zIndex: 999,
                  display: this.state.loading ? 'block' : 'none'
                }
              }}
            >
            </LoadingOverlay>
          )
        }
        <header className="masthead">
          <div className="container h-100">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <Countdown wait={wait} days={days} hours={hours} minutes={minutes} seconds={seconds} />
              </div>
            </div>
            <br />
            <br />

            {live && live.video_id ? (<>
              <div className="row" >
                <div className="col-md-12 mx-auto 1">
                  {live.type === 'YT' ? (
                    <div class='embed-container'>
                      <iframe src={`https://www.youtube.com/embed/${live.video_id}?autoplay=1&origin=http://aovivo.live4jesus.com.br`} frameborder='0' allowfullscreen title={live.title}></iframe>
                    </div>
                  ) : (
                      <div className="fb-video"
                        data-href={`https://www.facebook.com/video.php?v=${live.video_id}`}
                        data-width="auto"
                        data-autoplay="true"
                        data-show-captions="true"
                        data-allowfullscreen="true">
                      </div>
                    )}
                </div>
              </div>
              {/* {!video && (
                <>
                  <br />
                  <div className="row" >
                    <div className="col-md-12 mx-auto 2" style={{}}>
                      <div style={{ backgroundColor: '#B03363', padding: '20px 0 20px 20px', borderRadius: 8, }}>
                        <Reactions video_id={live.video_id} token={token} />
                        <Comments video_id={live.video_id} token={token} />
                      </div>
                    </div>
                  </div>
                </>
              )} */}
            </>
            ) : (
                <div className="row" >
                  <div className="col-md-12 mx-auto 3" style={{ textAlign: 'center' }}>
                    {(display === 'B' && banner) && (<img className="img-fluid img-thumbnail" src={`http://aovivo.live4jesus.com.br/storage/${banner}`} alt="" />)}
                    {(display === 'V') && (
                      <video width="100%" autoPlay muted loop controls>
                        <source src={'http://aovivo.live4jesus.com.br/img/vinheta-l4j.mp4'} type="video/mp4" />
                      </video>
                    )}
                  </div>
                </div>
              )}

          </div>
        </header>

        <section style={{ paddingTop: 50, paddingBottom: 0 }}>
          <div className="container">
            <div className="row">
              <div className="col-md-8 mx-auto">
                <div>
                  <div className="row">
                    <div className="col-md-4 mx-auto text-center">
                      <b style={{ fontSize: 18 }}>Assista pelo:</b>
                    </div>
                    <div className="col-md-4 mx-auto text-center">
                      <a href="https://www.facebook.com/SoBoasNovas/" style={{ textDecoration: 'none' }} rel="noopener noreferrer" target="_blank"><img src="/img/facebook.png" className="img-fluid" style={{ width: 80, verticalAlign: 'baseline' }} alt="" /> <b style={{ fontSize: 18 }}> /soboasnovas</b></a>
                    </div>
                    <div className="col-md-4 mx-auto text-center">
                      <a href="https://www.youtube.com/SoBoasNovas" style={{ textDecoration: 'none' }} rel="noopener noreferrer" target="_blank"><img src="/img/youtube.png" className="img-fluid" style={{ width: 80, verticalAlign: 'baseline' }} alt="" /> <b style={{ fontSize: 18 }}> /soboasnovas</b></a>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
        </section>


          <section className="features" id="features" style={{ paddingTop: 50 }}>
            <div className="container">
              <div className="row">
                <div className="col-md-8 mx-auto">
                  <h2>ÚLTIMOS VÍDEOS</h2>
                  {this.state.lives.length > 0 && (
                    <table className="table" style={{ maxWidth: 720, margin: 'auto' }}>
                      <tbody>
                        {this.state.lives.map((live, key) => {
                          return (
                            <tr key={key} onClick={() => this.openVideo(live)} style={{ cursor: 'pointer' }}>
                              <td style={{ width: 120 }}>{live.thumbnail ? (<img className="img-fluid img-thumbnail" src={`/storage/${live.thumbnail}`} alt="" />) : (<img className="img-fluid img-thumbnail" src={'/img/thumbnail.png'} alt="" />)}</td>
                              <td>
                                {live.date_at ? moment(live.date_at).format('DD/MM HH:mm') + ' - ' : ''}
                                {live.title}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </section>

      </>
    );
  }

}

export default App;
